// Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Components
import { AccountPasswordModalComponent } from '@app/baxtrack/shared/components/account-password-modal';
// Models
import { AccountModel, BusinessUnitModel } from '@data/models';
// Services
import { AuthService } from '@data/services/auth.service';
import { BusinessUnitsService } from '@data/services/business-units.service';
import { AccountService } from '@data/services/account.service';
import { EnumService } from '@data/services/enum.service';
import { OverlayService } from '@libs/core/services/overlay.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html'
})
export class BaseLayoutComponent implements OnInit {

  public account: AccountModel;
  public businessUnits: Array<BusinessUnitModel & { selected: boolean; }>;

  public visibleMenu: boolean;

  constructor(private router: Router,
              private authService: AuthService,
              private businessUnitsService: BusinessUnitsService,
              private accountService: AccountService,
              private enumService: EnumService,
              private overlayService: OverlayService) {
    this.account = this.accountService.getCurrentAccount()!;
    this.businessUnits = [];

    this.visibleMenu = false;
  }

  public ngOnInit(): void {
    this.businessUnits = this.account.businessUnits.map(businessUnit => ({ ...businessUnit, selected: false }));
    this.businessUnitsService.allBusinessUnits = this.businessUnits;

    if (!this.businessUnits.length) {
      this.enumService.getEnums()
        .subscribe(enums => {
          const { businessUnits } = enums!;
          this.businessUnits = businessUnits.map(businessUnit => ({ ...businessUnit, selected: false }));
          this.businessUnitsService.allBusinessUnits = this.businessUnits;
        });
    }
  }

  public get selectedBusinessUnits(): Array<BusinessUnitModel> {
    return this.businessUnits.filter(({ selected }) => selected);
  }

  public changeSelectBusinessUnits(): void {
    this.businessUnitsService.setBusinessUnits([...this.selectedBusinessUnits]);
  }

  public selectAllBusinessUnits(): void {
    const selected = this.businessUnits.length !== this.selectedBusinessUnits.length;
    this.businessUnits.forEach(businessUnit => {
      businessUnit.selected = selected;
    });
    this.businessUnitsService.setBusinessUnits([...this.selectedBusinessUnits]);
  }

  public toggleMenu(): void {
    this.visibleMenu = !this.visibleMenu;
  }

  public changePassword(): void {
    const overlay = this.overlayService.create(AccountPasswordModalComponent);
    overlay.instance.account = this.account;
    overlay.present();
  }

  public logout(): void {
    this.router.navigate(['/auth/login'])
      .then(() => {
        this.accountService.setCurrentAccount(null);
        this.businessUnitsService.setBusinessUnits([]);
        this.authService.logout();
      });
  }
}
