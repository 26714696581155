// Angular
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
// Guards
import { AdminGuard } from './guards/admin.guard';
// Interceptors
import { AdminInterceptor } from './interceptors/admin.interceptor';
// Layouts
import { BaseLayoutComponent } from './layouts/base-layout';
// Components
import { AppComponent } from './app.component';
// Routing
import { AppRoutingModule } from './app-routing.module';
// Shared
import { SharedModule } from './shared/shared.module';
// Data
import { DataModule } from '@data/data.module';
// Environments
import { environment } from '@env/environment';
// Libraries
import { CoreModule } from '@libs/core/core.module';
// External
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localePl, 'pl');

const LocaleProvider = {
  provide: LOCALE_ID,
  useValue: 'pl'
};

const AdminInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AdminInterceptor,
  multi: true
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    DataModule.forRoot(environment.apiBaxtrackUrl),
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    BaseLayoutComponent,
    AppComponent
  ],
  providers: [
    LocaleProvider,
    AdminInterceptorProvider,
    AdminGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

  constructor() {
  }
}
